
:root{
  --mainBlue:#30308b;
  --lightBlue:#009ffd;
  --lightBlue2:#268cc7;
  --lightBlue3:#65c5f8;
  --mainWhite: #f3f3f3;
  --mainDark: #232528;
  --mainDark2:#101111;
  --lightOrange: #ffa400;
  --lightOrange2:#fab83d;
  --darkOrange:#cc8604;
  --lightYellow: #ffff00;
  --lightGreen: #269226;
  --lightGrey: #cecccc;
  --lightGrey2: #7f7878;
  --lightGrey3: #a29494;
  --lightDark2: #3d3c3c;
  --lightDark3: #1b1a1a;
  --mainMaroon: #800000;
  --darkBlue: #11111d;
  --lightBlack: #0d0d0e;
  --lightRed: #eb0b0b;
  --brightRed: #FF0000;
  --pureBlack:#000000;
  --darkGreen: #031a03;
  --primary: hsla(245,97%,63%,0.65);
  --secondary: hsla(30,96%,62%,0.65);
  --lightDark: #0b0b14;
  --testColor:  #7b7a7a;
  
}

body{
  font-family: "Oswald",sans-serif !important;
  background: var(--mainWhite) !important;
  color: var(--mainDark) !important;
  
}

::-webkit-scrollbar{
  width:0.8rem;
  /* background: var(--mainDark); */
  background-color: #181818;

}

::-webkit-scrollbar-track{
  border-radius:0.5rem;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.25) !important;
}

::-webkit-scrollbar-thumb{
  /* border-radius:0.5rem;
background: linear-gradient(transparent,#30ff00);  
background: var(--lightGrey);
min-height: 20px; */

background-color: #818B99;
border: 2px solid transparent;
border-radius: 9px;
background-clip: content-box;
}

/* ::-webkit-scrollbar-thumb:hover{
   background: var(--lightDark);
} */